* {
    box-sizing: border-box;
  }  

.background{
  height: 86vh;
  width: 100%;
  background-image: url('./bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.container {
    width: 300px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff3a;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.623);
    position: fixed;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .upload-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .file-input {
    display: none;
  }
  
  .file-label {
    background-color: #3f51b5;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: background-color 0.3s;
  }
  
  .file-label:hover {
    background-color: #2c3e9c;
  }
  
  .file-name {
    margin-bottom: 20px;
    color: #fff;
    font-weight: 700;
  }
  
  .upload-btn {
    background-color: #4caf50;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .upload-btn:hover {
    background-color: #45a049;
  }
  
  @media (max-width: 480px) {
    .container {
      margin: 20px;
      padding: 10px;
      width: auto;
    }
  }
  

  