.visitor-form {
    background-color: var(--addvisitor_form_bg);
    position: absolute;
    right: -10%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 700px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    align-items: center;
    align-content: center;
    display: flex;
    flex-direction: column;
}

.visitor-form-heading{
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--addvisitor_form_title);
    margin-bottom: 20px;
}

.element-group{
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.visitor-form-text-field{
    width: 300px;
    background-color: var(--addvisitor_form_textfield_bg);
    border-radius: 5px;
}

.visitor-form-text-field-number{
    width: 300px;
    background-color: var(--addvisitor_form_textfield_bg);
    border-radius: 5px;
    -moz-appearance: textfield;
}

.visitor-form-text-area{
    width: 620px;
    background-color: var(--addvisitor_form_textfield_bg);
    border-radius: 5px;
}

input[type="date"]:in-range::-webkit-datetime-edit-year-field, 
input[type="date"]:in-range::-webkit-datetime-edit-month-field, 
input[type="date"]:in-range::-webkit-datetime-edit-day-field {
    color: transparent;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.visitor-form-dropdown{
    width: 300px;
    height: 56px;
}


.visitor-add-button{
    width: 200px;
}

.visitor-add-button:hover{
    background-color: #001e44;
    color: white;
}