.navbar {
    height: 65px;
    background-color: var(--navbar_background);
    color: var(--navbar_text);
    box-shadow: var(--navbar_boxshadow);
    position: fixed;
    width: 100%;
    z-index: 998;
}

.navlogo{
    text-decoration: none;  
    color: var(--navbar_text);  
}

.navlogo-img{
    height: 50px;
    border-radius: 25px;
    box-shadow: 0 0 25px 0 rgba(104, 104, 104, 0.2);
    background-color: var(--navbar_background);
    top: 7px;
    left: 20px;
    position: fixed;
}
.navlogo-img:hover{
    transform:scale(1.1);
    transform: rotate(360deg);
    transition: all 0.3s ease-in-out;
}


.navtext {
    position: fixed;
    color: var(--navbar_text);
    font-size: 18px;
    margin-top: 20px;
    margin-left: 80px;
}


.dash-avatarr {
    position: fixed;
    top: 7px;
    right: 20px;
    z-index: 999;
}

.dash-btn {
    position: fixed;
    top: 13px;
    right: 20px;
    z-index: 999;
}

.dash-btn-left {
    position: fixed;
    top: 13px;
    right: 65px;
    z-index: 999;
}


.theme-toggle-btn {
    position: fixed;
    top: 8px;
    right: 8px;
    z-index: 999;
}

.theme-toggle-btn-dash {
    position: fixed;
    top: 8px;
    right: 35px;
    z-index: 999;
}

.accountmenu{
    position: fixed;
    top: 8px;
    right: 8px;
    z-index: 999;
}

.avatar-bg{
    background-color: var(--avatar_bg);
    border-radius: 50%;
    border: 1px solid var(--avatar_border);
}