@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
    overflow: hidden;
}

.dashboard-sidebar {
    height: 86.4vh;
    background-color: var(--body-background);
    width: 250px;
    border-right: 1px solid #e0e0e0;
}

.dashboard-cards {
    height: 100px;
    width: 250px;
    background-color: #e0e0e0;
    border-radius: 10px;
}

.fake-nav {
    height: 65px;
    width: 100%;
}

.paper-cards {
    width: 100px;
}


/* center align this div */
.cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.card-internal {
    justify-content: center;
    align-items: center;
    font-size: large;
}

.sidebar {
    position: absolute;
    width: 18.3%;
    background-color: var(--body_background);
    box-shadow: 0px 0px 10px 0px var(--sidebar-rightborder);
    height: 90%;
}

.menu-text {
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
}

.menu-text:hover {
    transform: scaleX(1.1);
    transition: all 0.5s ease-in-out;
}

.sidebar-dash-btn {
    height: 50px;
    color: var(--body_background);
    background-color: var(--body-background);
    color: var(--body_color);
    border-bottom: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-size: 18px;
}

.sidebar-time {
    height: 50px;
    position: fixed;
    bottom: 95px;
    width: 250px;
    border-top: 1px solid var(--livetime-bordertop);
    color: var(--body_background);
    background-color: var(--body-background);
    color: var(--body_color);
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-size: 18px;
    cursor: default;
}


.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: fixed;
    right: 25px;
}

.card-main {
    width: 250px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 6px var(--dash-card-shadow);
    margin-top: 15px;
    margin-left: 8px;
    margin-right: 8px;
    padding: 0px;
    text-align: center;
}

.card1 {
    background-color: #f5f5f5;
    background-image: url(https://wallup.net/wp-content/uploads/2016/03/09/327141-abstract-blurred.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.card2 {
    background-color: #f5f5f5;
    background-image: url(https://wallup.net/wp-content/uploads/2016/03/09/327141-abstract-blurred.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.card3 {
    background-color: #f5f5f5;
    background-image: url(https://wallup.net/wp-content/uploads/2016/03/09/327141-abstract-blurred.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.card4 {
    background-color: #f5f5f5;
    background-image: url(https://wallup.net/wp-content/uploads/2016/03/09/327141-abstract-blurred.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


.card-main h1 {
    color: #ffffff;
    margin-top: 15px;
    margin-left: 15px;
    display: flex;
    align-self: flex-start;
}

.card-main:hover{
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
}

.card-main p{
    color: #ffffff;
    margin-top: 5px;
    margin-left: 15px;
    display: flex;
    align-self: flex-start;
    position: inherit;
    font-weight: bold;
}

.btnn{
    color: white;
    font-weight: bold;
    background-color: blueviolet;
    height: 30px;
    width: 100%;
    margin: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.camera-modal{
    color: white;
    font-weight: bold;
    background-color: blueviolet;
    height: 50px;
    width: 100%;
    margin: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}