html {
    height: 100%;
    box-sizing: border-box;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  body {
    font-family: "Helvetica Neue", Arial, sans-serif;
  }
  .footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 15px;
    border-top: 1.5px solid var(--footer_bordertop);
    background-color: var(--footer_background);
  }
  
  
  .footer-text{
    text-align: center;
    font-weight: bold;
    padding: 5px;
    color: var(--footer_text);
  }
  
  .footer-icons{
    text-align: center;
  }
  
  .icon-bg{
    background-color: var(--icon_background);
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: inline-block;
    text-align: center;
    padding: 5px;
  }
  
  .footer-icons{
    color: var(--icon_color);
  }

  .yt:hover{
    background-color: #ff0000;
    transition: all 0.3s ease-in-out;
    color: white;
  }
  
  .fb:hover{
    background-color: #3b5998;
    transition: all 0.3s ease-in-out;
  }
  
  .tw:hover{
    background-color: #00aced;
    transition: all 0.3s ease-in-out;
  }
  
  .ig:hover{
    background-color: #d400ff;
    transition: all 0.3s ease-in-out;
  }

  .footer_url{
    text-decoration: none;
    color:var(--footer_url_color);
  }