.visitorinfo-container {
    background-color: cadetblue;
    width: 1000px;
    height: 465px;
    top: 100px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-content: center;
    position: absolute;
}

.visitor-search{
    font-weight: bolder;
}

.profile-table{
    position: relative;
    left:19.5%;
    top: 15px;
    width: 79.5%;
    height: auto;
    border: 1px solid black;
    background-color: white;
}

.profile-table-content{
    position: relative;
    left:19.5%;
    top: 15px;
    width: 79.5%;
    height: auto;
    background-color: white;
}

.profile-pic{
    height: 100px;
    background-color: aqua;
    border-radius: 50%;
    box-shadow: 0 0 10px 0 #000;
}

.profile-pic:hover{
    box-shadow: 0 0 20px 0 #000;
    transform: scale(1.05);
}

.visitor-name{
    margin-top: 1px;
    margin-bottom: 2px;
}

.visitor-email{
    margin-top: 1px;
}

.profile-table-btn{
    width: 300px;
}