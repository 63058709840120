.table-container{
    position: fixed;
    top: 80px;
    overflow: scroll;
    overflow-x: hidden;
    height: 500px;
    right: 50px;
    width: 1000px;
    margin: 0 auto;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    background-color: aliceblue;
    box-shadow: 0px 0px 10px 0px var(--dash-card-shadow);
}

.table{
    border-radius: 5px;
    scrollbar-width: 0px;
    align-items: center;
    align-content: center;
    color: var(--table-color);
}

.table-body{
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    align-items: center;
    align-content: center;
    color: var(--table-color);
}

.table-row{
    align-items: center !important;
    align-content: center !important;
}

.table-cell-body{
    background-color: var(--table-bg-color);
    color: var(--table-color) !important;
    height: 40px;
    text-align: center;
    align-items: center;
}

.viewvisitor-btn{
    width: 175px;
}

.viewvisitor-btn:hover{
    background-color: #004297;
    color: white;
    box-shadow: 0px 0px 10px 0px var(--dash-card-shadow);
}

.visitor-search{
    height: 30px;
}