/* Google Fonts Import */
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

body{
    background: var(--body_background);
    color: var(--body_color);
    font-family: 'Quicksand', sans-serif;
}

.login-form{
    height: 400px;
    width: 400px;
    margin: 0 auto;
    padding: 30px 0;
    background: var(--logindiv_bg);
    box-shadow: var(--logindiv_boxshadow);
    border-radius: 16px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


.login-title{
    text-align: center;
    font-size: 35px;
    color: var(--login-title);
    font-weight: 500;
    font-family: 'Quicksand', sans-serif;
    font-weight: bolder;
    margin-bottom: 20px;
}


.textfield{
    width: 300px;
    align-self: center;
    padding-top: 100px;
    background-color: var(--textfield_bg);
    border: none;
    border-radius: 5px;
}


.login-button{
    width: 300px;
    align-self: center;
    margin-top: 20px;
    margin-bottom: 20px;
    background: var(--navbar_background);
    color: var(--navbar_text);
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Quicksand', sans-serif;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

/* *********************** Responsive CSS *********************** */

/* For Desktop View */
@media screen and (min-width: 1024px) {

}

/* For Tablet View */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {

}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {

}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) and (orientation: landscape) {

}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {

}