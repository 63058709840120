:root{
  --body_background: #f5f5f5;
  --body_color: #202020;
  --navbar_background: #006eff;
  --navbar_text: #202020;
  --navbar_boxshadow: 0px 0px 10px 0px rgb(0, 0, 0);

  --footer_background: #f5f5f5;
  --footer_text: #333333bd;
  --icon_background: #c9c9c9;
  --icon_color: #333333;
  --footer_bordertop: #444444e7;
  --footer_url_color: #ff0000bd;

  --logindiv_bg: #f5f5f5;
  --logindiv_boxshadow: 0px 0px 5px 0px rgb(0, 0, 0, 0.692);
  --login-title: #202020;
  --textfield_bg: #f5f5f5;

  --addvisitor_form_bg: #f5f5f5;
  --addvisitor_form_boxshadow: 0px 0px 5px 0px rgb(0, 0, 0, 0.692);
  --addvisitor_form_title: #202020;
  --addvisitor_form_textfield_bg: #f5f5f5;

  --avatar_bg: #f5f5f5;
  --avatar_border: #001e44;

  --sidebar-rightborder: #1c1b1ba3;

  --livetime-bordertop: #444444e7;

  --dash-card-shadow:rgba(0, 0, 0, 0.288);

  --table-bg-color: #b8b8b8;
  --table-color: #202020;
}

[data-theme="dark"]{
  --body_background: #001e44;
  --body_color: #f5f5f5;
  --navbar_background: #001e44;
  --navbar_text: white;
  --navbar_boxshadow: 0px 0px 10px 0px rgba(189, 189, 189, 0.808);

  --footer_background: #001e44;
  --footer_text: #bdbdbd;
  --icon_background: #464646;
  --icon_color: #bdbdbd;
  --footer_bordertop: #686868da;
  --footer_url_color: #c90000;

  --logindiv_bg: #001e44;
  --logindiv_boxshadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.692);
  --login-title: #f5f5f5;
  --textfield_bg: #b4b4b4;

  --addvisitor_form_bg: #001e44;
  --addvisitor_form_boxshadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.692);
  --addvisitor_form_title: #f5f5f5;
  --addvisitor_form_textfield_bg: #b4b4b4;

  --avatar_bg: #001e44;
  --avatar_border: #f5f5f5;

  --sidebar-rightborder: #ffffffa3;

  --livetime-bordertop: #ffffff5e;

  --dash-card-shadow:rgba(255, 255, 255, 0.575);

  --table-bg-color: #001e44;
  --table-color: #f5f5f5;
}

*{
  transition: all 0.3s ease-in-out;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
